<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Industry</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="sectorFilter"
            placeholder="Select Industry" multiple :options="sectorOptions" class="w-100" :reduce="(val) => val.value"
            @input="(val) => $emit('update:sectorFilter', val)" />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Type</label>
          <v-select placeholder="Select Job Type" multiple :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="functionFilter" :options="functionOptions" class="w-100" :reduce="(val) => val.value"
            @input="(val) => $emit('update:functionFilter', val)" />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Course</label>
          <v-select placeholder="Select Course" multiple :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="courseFilter" :options="courseOptions" class="w-100" :reduce="(val) => val.value"
            @input="(val) => $emit('update:courseFilter', val)" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import { watch } from "@vue/composition-api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },

  // watch : {
  //   roleOptions : function () {
  //     console.log('roleOptions changed');
  //   }
  // },
  props: {
    sectorFilter: {
      type: [Array, null],
      default: null,
    },
    functionFilter: {
      type: [Array, null],
      default: null,
    },
    courseFilter: {
      type: [Array, null],
      default: null,
    },
    sectorOptions: {
      type: Array,
      required: true,
    },
    functionOptions: {
      type: Array,
      required: true,
    },
    courseOptions: {
      type: Array,
      required: true,
    },
  },

  watch: {
    sectorFilter(val) {
      // console.log(val)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__selected-options {
  input {
    &::placeholder {
      color: #043c57;
      opacity: 0.4;
      font-size: 16px;
    }
  }
}
</style>
