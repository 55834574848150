<template>
  <div>
    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <users-list-filters
      :sector-filter.sync="sectorFilter"
      :function-filter.sync="functionFilter"
      :course-filter.sync="courseFilter"
      :sector-options="sectorOptions"
      :function-options="functionOptions"
      :course-options="courseOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 jobs-table-header">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                style="font-size: 14px;"
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative jobs-table-main"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: CompanyName -->
        <template #cell(JobTitle)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                square
                :src="data.item.logo"
                :text="avatarText(data.item.CompanyName)"
                variant="transparent"
                class="text-secondary"
                :to="{ name: 'job-profile', params: { jobId: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'job-profile', params: { jobId: data.item.id } }"
              class=" d-block text-nowrap hover:text-primary jobtitle"
              style="font-weight:600;color:#043c57; "
            >
              {{ data.item.JobTitle }} 
            </b-link>
            <small class=" text-nowrap"> {{ data.item.CompanyName }}</small>
          </b-media>
        </template>

        <!-- Column: location -->
        <template #cell(location)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              methodGenerateLocation(data.item.location)
            }}</span>
          </div>
        </template>

                <!-- Column: category -->

        <template #cell(category)="data">
          <span class="text-nowrap">{{ data.item.category }}</span>
        </template>
        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: CTC -->
        <template #cell(ctc_upper)="data">
          <div class="text-nowrap">
            <!--<feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            /> -->
            <span class="align-text-top text-capitalize"
              >₹{{ parseFloat(data.item.ctc_lower).toFixed(1) }} -
              {{ parseFloat(data.item.ctc_upper).toFixed(1) }}</span
            >
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(Status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.Status)}`"
            class="text-capitalize font-weight-bold"
          >
            {{ data.item.Status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            style="font-size: 12px; border-radius: 10px;"
            :to="{ name: 'job-profile', params: { jobId: data.item.id } }"
            ><span> Details </span></b-button
          >
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./JobsListFilters.vue";
// import useUsersList from '../../../apps/user/users-list/useUsersList.js'
import useUsersList from "./allJobsList.js";
import Ripple from "vue-ripple-directive";

// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UsersListFilters,
    // UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Screening", value: "Screening" },
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "inactive" },
      { label: "Closed", value: "Closed" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      rawList,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      methodGenerateLocation,
      // Extra Filters
      sectorFilter,
      functionFilter,
      courseFilter,
    } = useUsersList();

    const sectorOptions = ref([]);
    const rawsector = [];
    const functionOptions = ref([]);
    const rawfunction = [];
    const courseOptions = ref([]);
    const coursebatch = [];

    watch([rawList], () => {
      //  console.log(rawList)
      if (rawList.value.length > 1) {
        rawList.value.forEach((item) => {
          if (rawsector.includes(item.CompanySector) == false) {
            rawsector.push(item.CompanySector);
            sectorOptions.value.push({
              label: item.CompanySector,
              value: item.CompanySector,
            });
          }
          if (rawfunction.includes(item.JobFunction) == false) {
            rawfunction.push(item.JobFunction);
            functionOptions.value.push({
              label: item.JobFunction,
              value: item.JobFunction,
            });
          }
          item.CourseName.forEach((course) => {
            if (coursebatch.includes(course) == false) {
              coursebatch.push(course);
              courseOptions.value.push({
                label: course,
                value: course,
              });
            }
          });
        });
      }
    });

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      methodGenerateLocation,
      // Extra Filters

      sectorFilter,
      courseFilter,
      functionFilter,
      sectorOptions,
      functionOptions,
      courseOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
  font-size: 14px;
}
.jobtitle{
  &:hover {
    color: #039be5 !important;
          transition: all 0.25s ease;

    }
}
.jobs-table-header {
  .vs--single .vs__dropdown-toggle {
    border-radius: 8px !important;
  }
  .form-control {
    height: 38px;
    border-radius: 12px;
  }
}
// .jobs-table-main ::v-deep td:nth-child(6) {
//   text-align: center;
// }
// .jobs-table-main ::v-deep th:nth-child(6) {
//   text-align: center;
// }
.jobs-table-main ::v-deep thead {
  tr:first-child{
    th{
      border-top: 1px solid #efefef; 
    }
  }
    tr:first-child {
      th:first-child {
        border-top-left-radius: 0px;
      }
      th:last-child {
        border-top-right-radius: 0px;
      }
    }
  }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
