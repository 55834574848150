import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { paginateArray, sortCompare } from "@/@fake-db/utils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const getFirebaseData = async () => {
  let productsList = [];
  // console.log(store.state);
  var studentCode = ''
  if(store.state.mainUserCodes){
  studentCode = store.state.mainUserCodes.StudentCode
}
  let result = await firebase
    .firestore()
    .collection("RecruiterJobs")
    .where("Colleges", "in", ["All", studentCode]).orderBy("timestamp")
    .get()
    .then((docs) => {
      // console.log(docs.size)
      docs.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        productsList.push(obj);
      });
    })
    .then(() => {
      return productsList;
      // console.log('value added')
    })
    .catch((err) => {
      // console.log(err);
    });
  // console.log(result);
  return result;
};

const getDataandSort = async (config) => {
  let users = await getFirebaseData();
  // console.log('hi this users');
  // console.log(users.length);
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "",
    sortDesc = false,
    sector = [],
    funct = [],
    course = [],
  } = config;

  const queryLowered = q.toLowerCase();
  // const filteredData = users
  //     console.log(filteredData.length);

  let user = users.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      user.CompanyName.toLowerCase().includes(queryLowered) ||
      user.JobTitle.toLowerCase().includes(queryLowered) ||
      user.category.toLowerCase().includes(queryLowered) ||
      user.location.includes(queryLowered)
  );
  if (sector.length > 0) {
    let arr = [];
    user.forEach((usr) => {
      sector.forEach((elem) => {
        if (usr.CompanySector === elem) arr.push(usr);
      });
    });
    user = arr;
  }

  if (funct.length > 0) {
    let arr2 = [];
    user.forEach((usr) => {
      funct.forEach((elem) => {
        if (usr.JobFunction === elem) arr2.push(usr);
      });
    });

    // console.log(arr2);
    user = arr2;
  }
  if (course.length > 0) {
    let arr3 = [];
    user.forEach((usr) => {
      course.forEach((elem) => {
        if (usr.CourseName.includes(elem)) arr3.push(usr);
      });
    });

    // console.log(arr2);
    user = arr3;
  }

  const sortedData = sortBy !== "" ? user.sort(sortCompare(sortBy)): user;
  if (sortDesc) sortedData.reverse();
  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: sortedData.length,
      rawUsers: users,
    },
  ];
};
export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // {
    //   key: "CompanyName",
    //   label: "Company Name",
    //   formatter: title,
    //   sortable: true,
    // },
    {
      key: "JobTitle",
      label: "Job Title",
      formatter: title,
      sortable: true,
    },
    {
      key: "JobFunction",
      label: "Job Type",
      formatter: title,
      sortable: true,
    },
    {
      key: "location",
      label: "Job Location",
      formatter: title,
      sortable: true,
    },
    {
      key: "ctc_upper",
      label: "CTC (LPA)",
      formatter: title,
      sortable: true,
    },
    // { key: 'user', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'role', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
        { key: "category", sortable: true },

    { key: "Status", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("");
  const isSortDirDesc = ref(true);
  const sectorFilter = ref(null);
  const functionFilter = ref(null);
  const courseFilter = ref(null);
  const rawList = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      sectorFilter,
      functionFilter,
      courseFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    // store
    //   .dispatch('app-user/fetchUsers', {
    //     q: searchQuery.value,
    //     perPage: perPage.value,
    //     page: currentPage.value,
    //     sortBy: sortBy.value,
    //     sortDesc: isSortDirDesc.value,
    //     role: roleFilter.value,
    //     plan: planFilter.value,
    //     status: statusFilter.value,
    //   })
    // console.log(`this searchQuery obj ${searchQuery.value.length}`);
    getDataandSort({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      sector: sectorFilter.value ?? [],
      funct: functionFilter.value ?? [],
      course: courseFilter.value ?? [],
    })
      .then((response) => {
        // console.log(response);
        const { users, total, rawUsers } = response[1];
        rawList.value = rawUsers;
        // console.log(rawUsers)

        callback(users);
        totalUsers.value = total;
      })
      .catch((err) => {
        // console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "Screening") return "warning";
    if (status === "Active") return "success";
    if (status === "Closed") return "danger";
    return "primary";
  };

  const methodGenerateLocation = (location) => {
    var locationStr = "";
    location.forEach((val) => {
      var valueList = val.split(",");
      if (locationStr.length > 0) {
        locationStr = locationStr + "," + " " + valueList[0];
      } else {
        locationStr = valueList[0];
      }
    });
    if (locationStr.length > 25) {
      return locationStr.substring(0, 25) + "...more";
    } else {
      return locationStr;
    }
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    rawList,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    methodGenerateLocation,
    // Extra Filters
    sectorFilter,
    functionFilter,
    courseFilter,
  };
}
